




































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import { Getter, namespace } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const communityUserNoteStore = namespace('CommunityUserNoteStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonNote extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: string | null;

  @communityUserNoteStore.State
  private noteLinkedEntityCount!: number;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private get isDisplayed(): boolean {
    return this.actionType === this.ActionButtonType.ADD_NOTE
        && this.featureByKey(FeatureKeys.NOTES)
        && this.featureByKey(FeatureKeys.NOTES).enabled;
  }

  private get btnText(): string {
    return this.noteLinkedEntityCount > 0
      ? `${this.$tc('actions.add-note-count', 0, { noteCount: this.noteLinkedEntityCount })}`
      : `${this.$t('actions.add-note')}`;
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private toggleNote(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    const links = [
      {
        uid: this.entityUid,
        targetType: this.entityType,
        target: this.actionResult,
      },
    ];

    this.$eventsBus.emit('ontoolbox', {
      view: ToolbarMenuActions.TOOLBAR_NOTES,
      mode: 'create',
      data: links,
    });
  }
}
